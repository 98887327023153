import styles from "./Devices.css";
import Logo from "../Logo";

const DEV_TYPE = [styles.SmallDevice, styles.MediumDevice, styles.LargeDevice];

const devices = (props) => {
  let index = props.deviceType ? props.deviceType : 0;

  return (
    <div className={styles.DeviceContainer}>
      <div className={[styles.Device, DEV_TYPE[index]].join(" ")}>
        <div className={styles.content}>
          <div className={styles.cover}>
            <Logo />
          </div>
          <div className={styles.console}></div>
          <div className={styles.console}></div>
          <div className={styles.console}></div>
          <div className={styles.console}></div>
          <div className={styles.console}></div>
          <div className={styles.console}></div>
        </div>
      </div>
      <div className={styles.Children}>{props.children}</div>
    </div>
  );
};

export default devices;
