export const formatDate = (date) => {
  let newDate = null;
  if (date) {
    const formatedDate = new Date(date);
    newDate =
      formatedDate.getFullYear() +
      "-" +
      ("0" + (parseInt(formatedDate.getMonth()) + 1)).slice(-2) +
      "-" +
      ("0" + formatedDate.getDate()).slice(-2);
  }

  return newDate;
};

export const showDate = (date) => {
  let newDate = null;
  if (date) {
    const formatedDate = new Date(date);
    newDate =
      ("0" + formatedDate.getDate()).slice(-2) +
      "/" +
      ("0" + (parseInt(formatedDate.getMonth()) + 1)).slice(-2) +
      "/" +
      formatedDate.getFullYear();
  }

  return newDate;
};

export const showDateAndTime = (date) => {
  let newDate = null;
  if (date) {
    const formatedDate = new Date(date);
    newDate =
      ("0" + formatedDate.getDate()).slice(-2) +
      "/" +
      ("0" + (parseInt(formatedDate.getMonth()) + 1)).slice(-2) +
      "/" +
      formatedDate.getFullYear() +
      "@" +
      ("0" + formatedDate.getHours()).slice(-2) +
      ":" +
      ("0" + formatedDate.getMinutes()).slice(-2);
  }
  return newDate;
};

export const getISTTime = () => {
  var currentTime = new Date();

  var currentOffset = currentTime.getTimezoneOffset();

  var ISTOffset = 330; // IST offset UTC +5:30

  var ISTTime = new Date(
    currentTime.getTime() + (ISTOffset + currentOffset) * 60000
  );

  // ISTTime now represents the time in IST coordinates
  return ISTTime;
};
