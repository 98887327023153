import { Icons } from "../../UI";
import styles from "./Footer.css";
const Footer = (props) => {
  return (
    <div className={styles.Footer}>
      <p className={styles.FooterText}>
        Made with{" "}
        <i
          className={[Icons["fas"], Icons["fa-rupee-sign"], styles.Money].join(
            " "
          )}
        ></i>{" "}
        and{" "}
        <i
          className={[Icons["fas"], Icons["fa-heart"], styles.Love].join(" ")}
        ></i>
        .
      </p>
      <div className={styles.FooterText}>
        <button className={styles.FooterNav}>Learning</button>•
        <button className={styles.FooterNav}>Jobs</button>•
        <button className={styles.FooterNav}>Feedback</button>
      </div>
      <div className={styles.FooterText}>
        <button
          onClick={
            props.showTerms
              ? () => {
                  props.showTerms();
                }
              : () => {}
          }
          className={styles.FooterNav}
        >
          Terms
        </button>
        •
        <button
          onClick={
            props.showPrivacy
              ? () => {
                  props.showPrivacy();
                }
              : () => {}
          }
          className={styles.FooterNav}
        >
          Privacy
        </button>
        •<button className={styles.FooterNav}>Disclaimer</button>
      </div>
    </div>
  );
};

export default Footer;
