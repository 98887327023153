import { Component, Fragment } from "react";
import Container from "../../../components/Container";
import Devices from "../../../components/Devices";
import WorkDesc from "../../../components/WorkDesc";
import WorkStatus from "../../../components/WorkStatus";
import RightIndicator from "../../../components/RightIndicator";

import styles from "./Welcome.css";

const DEVICES = ["apps", "websites", "softwares"];

class Welcome extends Component {
  state = { deviceType: 0 };
  componentDidMount() {
    this.deviceShiftHandler();
  }

  deviceShiftHandler = () => {
    let devIndex = this.state.deviceType + 1;
    let upLimit = 2;
    if (devIndex > upLimit) {
      devIndex = 0;
    }
    this.setState({ deviceType: devIndex }, () => {
      setTimeout(this.deviceShiftHandler, 5000);
    });
  };

  render() {
    return (
      <Fragment>
        <Container>
          <RightIndicator />
          <WorkStatus />
          <WorkDesc deviceType={DEVICES[this.state.deviceType]} />
        </Container>
        <Container>
          <Devices deviceType={this.state.deviceType}>
            <h4>Fully responsive, custom designs.</h4>
          </Devices>
        </Container>
      </Fragment>
    );
  }
}

export default Welcome;
