import styles from "./Backdrop.css";

const Backdrop = (props) => {
  return props.show ? (
    <div
      {...props}
      className={[styles.Backdrop, props.className].join(" ")}
      onClick={props.onClick}
    >
      {props.onClick ? <span>Tap To Close</span> : null}
    </div>
  ) : null;
};

export default Backdrop;
