import { Fragment } from "react";
import Container from "../../../components/Container";
import Section from "../../../components/Section";
import { HR, Icons } from "../../../UI";
import formBackground from "../../../assets/images/login.png";
import styles from "./Contact.css";
const Contact = (props) => {
  return (
    <Fragment>
      <Container></Container>
      <Container>
        <Section name="Let's Talk" backColor="white" className={styles.Contact}>
          <div className={styles.Overlay}></div>
          <p>+91-7839461992</p>
          <p>+91-9792521396</p>
          <p>info@sudhya.com</p>
          <p>help@sudhya.com</p>
          <HR>
            <i className={[Icons["fas"], Icons["fa-mail-bulk"]].join(" ")}></i>{" "}
            Address
          </HR>
          <p>S 7/205 Senpura, Chetganj,</p>
          <p>Varanasi, Uttar Pradesh</p>
          <p>India - 221001</p>
          <img
            className={styles.BackImage}
            src={formBackground}
            alt="Contact Form"
          ></img>
        </Section>
      </Container>
    </Fragment>
  );
};

export default Contact;
