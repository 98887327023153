import React, { Fragment } from "react";

import styles from "./HR.css";

const HR = (props) => {
  let hr = null;
  var color = props.color ? props.color : "var(--text-color)";
  var fontSize = props.fontSize ? props.fontSize : "14px";
  let child = props.children;

  if (child) {
    child =
      typeof child === "object"
        ? props.children
        : props.children.toString().toUpperCase();
  }

  if (props.children) {
    hr = (
      <Fragment>
        <div
          style={{ borderBottom: "1px solid " + color }}
          className={styles.HRStart}
        ></div>
        <div
          {...props}
          style={{ ...props.style, color: color }}
          className={[styles.HRSegment, props.className].join(" ")}
        >
          <span style={{ fontSize: fontSize }}>{child}</span>
        </div>
        <div
          style={{ borderBottom: "1px solid " + color }}
          className={styles.HREnd}
        ></div>
      </Fragment>
    );
  } else {
    hr = (
      <div
        style={{ borderBottom: "1px solid " + color }}
        className={styles.HREnd}
      ></div>
    );
  }

  return <div className={styles.HR}>{hr}</div>;
};

export default HR;
