import { Fragment } from "react";

import styles from "./Modal.css";
import Backdrop from "../Backdrop/Backdrop";
import { HR } from "../..";

const Modal = (props) => {
  const modal = props.show ? (
    <Fragment>
      <Backdrop show={true} onClick={props.modalClosed} />
      <div className={styles.Modal}>
        <div className={styles.Heading}>
          <HR>{props.title}</HR>
        </div>
        {props.children}
      </div>
    </Fragment>
  ) : null;

  return modal;
};

export default Modal;
