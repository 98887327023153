import { Icons, Input, Modal, Snackbar } from "../../UI";
import styles from "../SocialSharing/SocialSharing.css";

const LINKS = [
  {
    className: [styles.SocialIcon, styles.Facebook].join(" "),
    link: "https://www.facebook.com/sudhyadc",
    iconSet: (
      <i className={[Icons["fab"], Icons["fa-facebook-square"]].join(" ")}></i>
    ),
  },
  {
    className: [styles.SocialIcon, styles.Instagram].join(" "),
    link: "https://instagram.com/sudhyadc",
    iconSet: (
      <i className={[Icons["fab"], Icons["fa-instagram"]].join(" ")}></i>
    ),
  },
  {
    className: [styles.SocialIcon, styles.Messenger].join(" "),
    link: "https://m.me/sudhyadc",
    iconSet: (
      <i
        className={[Icons["fab"], Icons["fa-facebook-messenger"]].join(" ")}
      ></i>
    ),
  },
  {
    className: [styles.SocialIcon, styles.Twitter].join(" "),
    link: "https://twitter.com/sudhyadc",
    iconSet: (
      <i className={[Icons["fab"], Icons["fa-twitter-square"]].join(" ")}></i>
    ),
  },
  {
    className: [styles.SocialIcon, styles.WhatsApp].join(" "),
    link: "https://whatsapp.sudhya.com",
    iconSet: <i className={[Icons["fab"], Icons["fa-whatsapp"]].join(" ")}></i>,
  },
];

const SocialSharing = (props) => {
  const socialClickHandler = (index) => {
    window.open(LINKS[index].link);
  };

  return (
    <Modal show={props.show} modalClosed={props.onClose} title="Follow Us">
      <div className={styles.SocialSharing}>
        {LINKS.map((link, index) => {
          return (
            <button
              key={"social" + index}
              onClick={() => {
                socialClickHandler(index);
              }}
              className={link.className}
            >
              {link.iconSet}
            </button>
          );
        })}
      </div>
    </Modal>
  );
};

export default SocialSharing;
