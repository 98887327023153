import { Icons } from "../../UI";
import styles from "./RightIndicator.css";

const RightIndicator = (props) => {
  return (
    <div className={styles.RightIndicator}>
      <span>Let's go...</span>
      <i className={[Icons["fas"], Icons["fa-plane"]].join(" ")}></i>
    </div>
  );
};

export default RightIndicator;
