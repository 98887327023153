import React, { Component } from "react";
import { render } from "react-dom";

import styles from "./Snackbar.css";

class Snackbar extends Component {
  static show = (message = "", args = {}) => {
    message = message.toString();
    const snackbarElement = document.createElement("div");
    snackbarElement.style.visibility = "none";
    snackbarElement.style.maxHeight = "0";
    snackbarElement.style.maxWidth = "0";
    document.body.appendChild(snackbarElement);
    render(
      <Snackbar color={args.color} type={args.type}>
        {message}
      </Snackbar>,
      snackbarElement
    );
    setTimeout(() => {
      document.body.removeChild(snackbarElement);
    }, 4900);
  };

  render() {
    let snackType = styles.Primary;
    if (this.props.type) {
      switch (this.props.type.toString().toLowerCase()) {
        case "info":
          snackType = styles.Primary;
          break;
        case "success":
          snackType = styles.Success;
          break;
        case "warning":
          snackType = styles.Warning;
          break;
        case "danger":
          snackType = styles.Danger;
          break;
        default:
          snackType = styles.Primary;
      }
    }
    let color = this.props.color ? this.props.color : "var(--background-color)";

    return (
      <div
        className={[styles.Snackbar, snackType].join(" ")}
        style={{ color: color }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Snackbar;
