import { Icons, Input, Modal, Snackbar } from "../../UI";
import styles from "./SocialSharing.css";

const LINKS = [
  {
    className: [styles.SocialIcon, styles.Facebook].join(" "),
    link:
      "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ffacebook.com%2Fsudhyadc",
    iconSet: (
      <i className={[Icons["fab"], Icons["fa-facebook-square"]].join(" ")}></i>
    ),
  },
  {
    className: [styles.SocialIcon, styles.Twitter].join(" "),
    link:
      "https://twitter.com/intent/tweet?text=Hi!%20I%20found%20an%20amazing%20IT%20firm%20on%20twitter%2C%20give%20it%20a%20try.%0D%0A@sudhyadc%0D%0Ahttps://www.sudhya.com",
    iconSet: (
      <i className={[Icons["fab"], Icons["fa-twitter-square"]].join(" ")}></i>
    ),
  },
  {
    className: [styles.SocialIcon, styles.WhatsApp].join(" "),
    link:
      "https://web.whatsapp.com/send?text=Hi!%20I%20found%20an%20amazing%20IT%20firm%20in%20Varanasi%2C%20give%20it%20a%20try.%0D%0Ahttps://www.sudhya.com",
    iconSet: <i className={[Icons["fab"], Icons["fa-whatsapp"]].join(" ")}></i>,
  },
  {
    className: [styles.SocialIcon, styles.EMail].join(" "),
    link:
      "mailto:?subject=I%20found%20an%20amazing%20IT%20firm%20in%20Varanasi&body=Hi!%0D%0AI%20found%20an%20amazing%20IT%20firm%20in%20Varanasi%2C%20give%20it%20a%20try.%0D%0Ahttps://www.sudhya.com",
    iconSet: <i className={[Icons["far"], Icons["fa-envelope"]].join(" ")}></i>,
  },
];

const SocialSharing = (props) => {
  const link = {
    type: "text",
    label: "Link",
    value: "https://www.sudhya.com",
    elementConfig: {
      readOnly: true,
      onClick: (event) => {
        inputClickHandler(event);
      },
    },
  };

  const inputClickHandler = (event) => {
    const target = event.target;
    target.select();
    target.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");
    Snackbar.show(target.value + " copied to clipboard.", {
      color: "var(--text-color)",
    });
  };

  const socialClickHandler = (index) => {
    window.open(LINKS[index].link);
  };

  return (
    <Modal
      show={props.show}
      modalClosed={props.onClose}
      title="Spread the word..."
    >
      <div className={styles.SocialSharing}>
        {LINKS.map((link, index) => {
          return (
            <button
              key={"social" + index}
              onClick={() => {
                socialClickHandler(index);
              }}
              className={link.className}
            >
              {link.iconSet}
            </button>
          );
        })}
      </div>
      <Input {...link} />
    </Modal>
  );
};

export default SocialSharing;
