import styles from "./Layout.css";
import backgroundImage from "../../assets/images/tv.png";
import AnimateBackground from "../../components/AnimateBackground";
import TopNav from "../../components/TopNav";
import Brand from "../../components/Brand";
import Footer from "../../components/Footer";
import { Component, createRef, Fragment } from "react";
import { Icons } from "../../UI";
import { Route, Switch } from "react-router";
import Home from "../Home";
import asyncComponent from "../../Helper/AsyncComponent";
const Privacy = asyncComponent(() => import("../../components/Privacy"));
const Terms = asyncComponent(() => import("../../components/Terms"));
const SHAPES = [
  <i
    style={{ color: "#ccff00" }}
    className={[Icons["fas"], Icons["fa-headphones"]].join(" ")}
  ></i>,
  <i
    style={{ color: "#55ffff" }}
    className={[Icons["fas"], Icons["fa-mobile"]].join(" ")}
  ></i>,
  <i className={[Icons["fas"], Icons["fa-laptop-code"]].join(" ")}></i>,
  <i
    style={{ color: "#ffcf09" }}
    className={[Icons["fas"], Icons["fa-pizza-slice"]].join(" ")}
  ></i>,
  <i
    style={{ color: "var(--primary)" }}
    className={[Icons["fas"], Icons["fa-compact-disc"]].join(" ")}
  ></i>,
  <i
    style={{ color: "var(--danger)" }}
    className={[Icons["fas"], Icons["fa-heart"]].join(" ")}
  ></i>,
  <i
    style={{ color: "goldenrod" }}
    className={[Icons["fas"], Icons["fa-grin-alt"]].join(" ")}
  ></i>,
  <i
    style={{ color: "var(--success)" }}
    className={[Icons["fas"], Icons["fa-rupee-sign"]].join(" ")}
  ></i>,
];

class Layout extends Component {
  state = {
    selectedShape: 0,
    showPrivacy: false,
    showTerms: false,
  };
  divRef = createRef();
  componentDidMount = () => {
    if (this.divRef.current.addEventListener) {
      // IE9, Chrome, Safari, Opera
      this.divRef.current.addEventListener(
        "mousewheel",
        this.scrollHandler,
        false
      );
      // Firefox
      this.divRef.current.addEventListener(
        "DOMMouseScroll",
        this.scrollHandler,
        false
      );
    } else {
      // IE 6/7/8
      this.divRef.current.attachEvent("onmousewheel", this.scrollHandler);
    }
  };

  componentWillUnmount = () => {
    if (this.divRef.current.removeEventListener) {
      // IE9, Chrome, Safari, Opera
      this.divRef.current.removeEventListener("mousewheel", this.scrollHandler);
      // Firefox
      this.divRef.current.removeEventListener(
        "DOMMouseScroll",
        this.scrollHandler
      );
    } else {
      // IE 6/7/8
      this.divRef.current.removeEvent("onmousewheel", this.scrollHandler);
    }
  };

  scrollHandler = (e) => {
    e = window.event || e;
    e.preventDefault();
    var delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
    this.divRef.current.scrollLeft -= delta * 200; // Multiplied by 200
  };

  selectShape = (index) => {
    if (SHAPES[index]) {
      this.setState({ selectedShape: index });
    } else {
      this.setState({ selectedShape: 0 });
    }
  };

  togglePrivacyModel = () => {
    this.setState((prevState) => ({
      showPrivacy: !prevState.showPrivacy,
    }));
  };
  toggleTermsModel = () => {
    this.setState((prevState) => ({
      showTerms: !prevState.showTerms,
    }));
  };

  render() {
    const backgroundStyle = {
      backgroundImage: "url(" + backgroundImage + ")",
      backgroundSize: "50px",
      backgroundRepeat: "repeat",
    };

    const routes = (
      <Switch>
        <Route to="/" component={Home} />
      </Switch>
    );

    return (
      <Fragment>
        <div className={styles.Layout}>
          <AnimateBackground shape={SHAPES[this.state.selectedShape]} />
          <TopNav
            shapes={SHAPES}
            selectedIndex={this.state.selectedShape}
            selectShape={this.selectShape}
          />
          <Brand />
          <div className={styles.Background} style={backgroundStyle}></div>
          <div ref={this.divRef} className={styles.Container}>
            <div className={styles.FlexContainer}>{routes}</div>
          </div>
          <Footer
            showPrivacy={this.togglePrivacyModel}
            showTerms={this.toggleTermsModel}
          />
        </div>
        {this.state.showPrivacy ? (
          <Privacy
            show={this.state.showPrivacy}
            modalClosed={this.togglePrivacyModel}
          />
        ) : null}
        {this.state.showTerms ? (
          <Terms
            show={this.state.showTerms}
            modalClosed={this.toggleTermsModel}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default Layout;
