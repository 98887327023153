import { Fragment, useEffect, useState } from "react";
import { getISTTime } from "../../Helper/Date";
import { Icons } from "../../UI";
import styles from "./workstatus.css";

const WORK_STATUS = {
  aboutToOpen: {
    text: "About to Open",
    className: "AboutToOpen",
  },
  open: {
    text: "Open for Work",
    className: "Open",
  },
  aboutToClose: {
    text: "About to Close",
    className: "AboutToClose",
  },
  closed: {
    text: "Now Closed",
    className: "Closed",
  },
  sundayOff: {
    text: "Sunday's off",
    className: "Closed",
  },
  off: {
    text: "",
    className: "Holiday",
  },
};
const MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const WorkStatus = (props) => {
  const [workStatus, setWorkStatus] = useState(WORK_STATUS.closed);
  const [date, setDate] = useState(getISTTime());

  useEffect(() => {
    getWorkStatus();
  }, []);

  const getWorkStatus = () => {
    setDate(getISTTime());
    if (date.getDay() > 0) {
      if (date.getHours() < 10 && date.getHours() > 9) {
        setWorkStatus(WORK_STATUS.aboutToOpen);
      } else if (date.getHours() < 18 && date.getHours() >= 10) {
        setWorkStatus(WORK_STATUS.open);
      } else if (date.getHours() < 20 && date.getHours() >= 18) {
        setWorkStatus(WORK_STATUS.aboutToClose);
      } else {
        setWorkStatus(WORK_STATUS.closed);
      }
    } else {
      setWorkStatus(WORK_STATUS.sundayOff);
    }
    setTimeout(getWorkStatus, 1000 * 60);
  };

  const renderCurrentDate = () => {
    const dateString = (
      <Fragment>
        <span className={styles.Date}>{("0" + date.getDate()).slice(-2)}</span>
        <span className={styles.Secondary}>
          <span className={styles.Month}>{MONTH_NAMES[date.getMonth()]}</span>
          <span
            className={[styles.Info, styles[workStatus.className]].join(" ")}
          >
            {workStatus.text}
          </span>
        </span>
      </Fragment>
    );
    return dateString;
  };

  return (
    <div className={styles.FashionContainer}>
      <div className={styles.CurrentDate}>{renderCurrentDate()}</div>
      <div className={styles.CircularDiv}>
        <i
          className={[
            Icons["fas"],
            Icons["fa-arrow-right"],
            styles.Indicator,
          ].join(" ")}
        ></i>
        <div className={styles.CircularMessage}>
          <div className={styles.ContactAction}>
            <a target="blank" href="https://whatsapp.sudhya.com">
              <i className={[Icons["fab"], Icons["fa-whatsapp"]].join(" ")}></i>
            </a>
            <a target="blank" href="tel:+91-9792521396">
              <i
                className={[Icons["fas"], Icons["fa-phone-square-alt"]].join(
                  " "
                )}
              ></i>
            </a>
          </div>
          <div className={styles.ContactAction}>
            <p>Contact Us:</p>
            <p>+91-7839461992</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkStatus;
