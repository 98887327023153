import React, { Component, Fragment } from "react";
import { render } from "react-dom";

import styles from "./PromptBox.css";
import { Backdrop } from "../..";

class PromptBox extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  defaultPrompt = {
    prompt: {
      title: "Message",
      message: "",
    },
  };

  handleCancel = () => {
    return new Promise((res) => {
      if (this.props.responseHandler) this.props.responseHandler(false);
      res();
    }).then(() => {
      this.remove();
    });
  };

  handleConfirm = () => {
    return new Promise((res) => {
      if (this.props.responseHandler) this.props.responseHandler(true);
      res();
    }).then(() => {
      this.remove();
    });
  };

  static show = (props = {}) => {
    if (document.getElementById("proptbox")) {
      document.body.removeChild(document.getElementById("proptbox"));
    }
    this.promptboxElement = document.createElement("div");
    this.promptboxElement.id = "proptbox";
    document.body.appendChild(this.promptboxElement);
    render(<PromptBox {...props} />, this.promptboxElement);
  };

  remove = () => {
    document.body.removeChild(document.getElementById("proptbox"));
  };

  render() {
    const prompt = this.state.prompt ? this.state.prompt : this.defaultPrompt;
    const { type, button } = this.state;

    let propmtStyle = [styles.PromptBox, styles.Info].join(" ");
    let defaultButton = null;
    switch (type) {
      case "warning":
        propmtStyle = [styles.PromptBox, styles.Warning].join(" ");
        prompt.title = prompt.title ? prompt.title : "Warning";
        break;
      case "error":
        propmtStyle = [styles.PromptBox, styles.Error].join(" ");
        prompt.title = prompt.title ? prompt.title : "Error";
        break;
      case "confirm":
        propmtStyle = [styles.PromptBox, styles.Message].join(" ");
        prompt.title = prompt.title ? prompt.title : "Confirm";
        break;
      default:
        propmtStyle = [styles.PromptBox, styles.Message].join(" ");
        prompt.title = prompt.title ? prompt.title : "Message";
    }
    switch (button) {
      case "yesno":
        defaultButton = (
          <Fragment>
            <button onClick={this.handleConfirm}>Yes</button>
            <button onClick={this.handleCancel}>No</button>
          </Fragment>
        );
        break;
      default:
        defaultButton = (
          <Fragment>
            <button onClick={this.handleCancel}>OK</button>
          </Fragment>
        );
    }

    const message = prompt.message;
    const title = prompt.title;

    return (
      <Fragment>
        <Backdrop show="true" clicked={this.props.closed} />
        <div className={propmtStyle}>
          <p className={styles.Header}>{title}</p>
          <p className={styles.Body}>{message}</p>
          <div className={styles.Footer}>{defaultButton}</div>
        </div>
      </Fragment>
    );
  }
}

export { PromptBox };
