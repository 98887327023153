import styles from "./Brand.css";
const Brand = (props) => {
  return (
    <div className={styles.Brand}>
      <span className={styles.BrandName}>Sudhya </span>
      <span className={styles.TagLine}>Developer's Core</span>
    </div>
  );
};

export default Brand;
