import styles from "./WorkDesc.css";
const WorkDesc = (props) => {
  return (
    <div className={styles.Header}>
      <h4 className={styles.Heading}>
        Freelance{" "}
        <big>
          <strong>Web Designer</strong>
        </big>{" "}
        &{" "}
        <big>
          <strong>Developer</strong>
        </big>{" "}
        based in Varanasi, India.
      </h4>
      <h4 className={styles.Heading}>
        Experienced in designing & developing custom{" "}
        <span>{props.deviceType}</span>.
      </h4>
    </div>
  );
};

export default WorkDesc;
