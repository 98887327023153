import { Fragment, useState } from "react";
import { Icons } from "../../UI";
import SocialSharing from "../SocialSharing";
import SocialProfile from "../SocialProfiles";
import Brand from "../Brand";
import styles from "./TopNav.css";

const TopNav = (props) => {
  const [showShare, setShowShare] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showEmojies, setShowEmojies] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const toggleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const toggleShowShare = () => {
    setShowShare(!showShare);
  };

  const toggleShowProfile = () => {
    setShowProfile(!showProfile);
  };

  const toggleShowEmojies = () => {
    setShowEmojies(!showEmojies);
  };

  const selectShape = (index) => {
    toggleShowEmojies();
    if (props.selectShape) {
      props.selectShape(index);
    }
  };

  const renderShapeDropdown = () => {
    return (
      <div
        className={
          showEmojies
            ? [styles.Dropdown, styles.Show].join(" ")
            : styles.Dropdown
        }
      >
        <button
          className={styles.Nav}
          onClick={toggleShowEmojies}
          title="Select Background"
        >
          {props.shapes[props.selectedIndex] ? (
            props.shapes[props.selectedIndex]
          ) : (
            <i className={[Icons["fas"], Icons["fa-question"]].join(" ")}></i>
          )}
        </button>
        <div
          className={
            showEmojies
              ? [styles.DropdownContent, styles.Show].join(" ")
              : styles.DropdownContent
          }
        >
          {props.shapes.map((shape, index) => {
            return (
              <button
                key={"social" + index}
                onClick={() => {
                  selectShape(index);
                }}
                className={styles.Nav}
              >
                {shape}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div
        className={
          openMenu ? [styles.Backdrop, styles.Open].join(" ") : styles.Backdrop
        }
      ></div>
      <div
        className={
          openMenu ? [styles.TopNav, styles.Open].join(" ") : styles.TopNav
        }
      >
        <Brand />
        <div className={styles.NavContainer}>
          <div className={styles.Showable}>
            {renderShapeDropdown()}
            <button
              className={[styles.Nav, styles.Hamburger].join(" ")}
              onClick={toggleOpenMenu}
            >
              {openMenu ? (
                <i
                  style={{ color: "var(--danger" }}
                  className={[Icons["fas"], Icons["fa-times"]].join(" ")}
                ></i>
              ) : (
                <i className={[Icons["fas"], Icons["fa-bars"]].join(" ")}></i>
              )}
            </button>
          </div>
          <div className={styles.Collapsable}>
            <button
              title="Social Profiles"
              className={styles.Nav}
              onClick={toggleShowProfile}
            >
              <i className={[Icons["fas"], Icons["fa-users"]].join(" ")}></i>{" "}
              <span>Social Profiles</span>
            </button>
            <button
              title="Share our work"
              className={styles.Nav}
              onClick={toggleShowShare}
            >
              <i
                className={[Icons["fas"], Icons["fa-share-alt"]].join(" ")}
              ></i>{" "}
              <span>Share our work</span>
            </button>
          </div>
        </div>
      </div>
      <SocialSharing show={showShare} onClose={toggleShowShare} />
      <SocialProfile show={showProfile} onClose={toggleShowProfile} />
    </Fragment>
  );
};

export default TopNav;
