import asyncComponent from "../../../Helper/AsyncComponent";

const SimpleColumn = asyncComponent(() =>
  import("./SimpleColumn/SimpleColumn")
);

const FoldableColumn = asyncComponent(() =>
  import("./FoldableColumn/FoldableColumn")
);

const Column = (props) => {
  return props.foldable === true || props.foldable === "true" ? (
    <FoldableColumn {...props}>{props.children}</FoldableColumn>
  ) : (
    <SimpleColumn {...props}>{props.children}</SimpleColumn>
  );
};

export default Column;
