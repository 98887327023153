import { Component, createRef, Fragment } from "react";
import AboutUs from "./AboutUs";
import Contact from "./Contact";
import Services from "./Services";
import Welcome from "./Welcome";

class Home extends Component {
  render() {
    return (
      <Fragment>
        <Welcome />
        <AboutUs />
        <Services />
        <Contact />
      </Fragment>
    );
  }
}

export default Home;
