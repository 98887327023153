import { Fragment } from "react";
import Container from "../../../components/Container";
import Section from "../../../components/Section";
import webDesignerBackground from "../../../assets/images/webdesignerbackground-min.png";
import webDeveloperBackground from "../../../assets/images/webdeveloperbackground-min.png";

import styles from "./AboutUs.css";

const AboutUs = (props) => {
  return (
    <Fragment>
      <Container>
        <Section
          name="About"
          backColor="var(--success)"
          className={styles.WebDEveloperDesigner}
        >
          <div className={styles.Overlay}></div>
          <h4 className={styles.Heading}>What is a Web Designer?</h4>
          <p>
            A web designer is someone who helps businesses and individual
            customers create websites that are artful and positively pleasing.
          </p>
          <p>
            They also work to ensure that the website is as functional as
            possible.
          </p>
          <p>
            This includes paying attention to aspects like the ease with which a
            user can navigate the website and take steps to ensure visitors
            carry out specific actions — making a purchase, signing up for an
            email newsletter, etc.
          </p>
          <img
            className={styles.BackImage}
            src={webDesignerBackground}
            alt="A web designer"
          ></img>
          <br />
          <p></p>
        </Section>
      </Container>
      <Container>
        <Section
          name="About"
          backColor="var(--info)"
          className={styles.WebDEveloperDesigner}
        >
          <div className={styles.Overlay}></div>
          <h4 className={styles.Heading}>What is a Web Developer?</h4>
          <p>
            A lot of people assume that web developers and web designers are the
            same. That’s not actually the case, though.
          </p>
          <p>
            While web designers focus on the design aspect of a website and take
            steps to make it appealing, web developers focus more on the backend
            of the website.
          </p>
          <p>
            They do the actual website building. They also work to maintain the
            website and ensure that it is running as efficiently as possible.
          </p>
          <img
            className={styles.BackImage}
            src={webDeveloperBackground}
            alt="A web developer"
          ></img>
        </Section>
      </Container>
    </Fragment>
  );
};

export default AboutUs;
