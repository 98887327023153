import { Fragment } from "react";
import styles from "./Container.css";

const Container = (props) => {
  const className = props.className
    ? [styles.Container, props.className].join(" ")
    : styles.Container;
  return (
    <div {...props} className={className} style={{ ...props.style }}>
      {props.children}
    </div>
  );
};

export default Container;
