import { Fragment } from "react";
import { Icons } from "../../UI";
import styles from "./AnimateBackground.css";

const AnimateBackground = (props) => {
  const renderShape = () => {
    let shape = [];
    for (let i = 0; i <= 11; ++i) {
      shape.push(
        <li key={"item" + i}>
          {props.shape ? (
            props.shape
          ) : (
            <i className={[Icons["fas"], Icons["fa-headphones"]].join(" ")}></i>
          )}
        </li>
      );
    }

    return shape;
  };

  return (
    <div className={styles.Area}>
      <ul className={styles.Circles}>{renderShape().map((item) => item)}</ul>
    </div>
  );
};

export default AnimateBackground;
