import { Fragment } from "react";
import Container from "../../../components/Container";
import Section from "../../../components/Section";
import serviceBackground from "../../../assets/images/servicesbackground-min.png";
import offerServicBackground from "../../../assets/images/BookKeeping.png";
import styles from "./Services.css";
const Services = (props) => {
  return (
    <Fragment>
      <Container></Container>
      <Container>
        <Section
          name="Our Services"
          backColor="var(--warning)"
          className={styles.Services}
        >
          <div className={styles.Overlay}></div>
          <p>
            Our creative web developers team are always ready to transform your
            imagination into an amazingly beautiful website. Our offer ranges
            from single static information page to database dependent complete
            dynamic website.
          </p>
          <p>
            CV/Resume, Product Showcase, Businesses Page, Promotional Web page,
            Institutional/Business Administration Website, e-Shopping Website
            and Document Repository are the major categories of web development
            we have been working on.
          </p>
          <img
            className={styles.BackImage}
            src={serviceBackground}
            alt="Web developing Services"
          ></img>
        </Section>
      </Container>
      <Container>
        <Section
          name="What We Offer"
          backColor="var(--primary)"
          className={styles.Services}
        >
          <div className={styles.Overlay}></div>
          <p>
            Not only web development, Our technical team can also provide,
            design, code, test, and support softwares for micro to medium
            companies. We work with numerous enterprises technologies to provide
            highly customized, scalable and integrated software solutions to
            digitally transform your businesses. Accounting, Inventory, and
            Academic administration are the expert arena of our developers. If
            you want to get rid of outdated pen and paper administration, give
            us a call or have a cup of coffee with us.
          </p>
          <img
            className={styles.BackImage}
            src={offerServicBackground}
            alt="We Offer web development"
          ></img>
        </Section>
      </Container>
    </Fragment>
  );
};

export default Services;
