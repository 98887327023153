import { HR } from "../../UI";
import styles from "./Section.css";
const Section = (props) => {
  const backColor = props.backColor ? props.backColor : "var(--primary)";
  const color = props.color ? props.color : "var(--text-color)";

  return (
    <div
      className={
        props.className
          ? [styles.Section, props.className].join(" ")
          : styles.Section
      }
      style={{ backgroundColor: backColor, color: color }}
    >
      <HR color={color}>{props.name}</HR>
      {props.children}
    </div>
  );
};

export default Section;
