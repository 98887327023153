import asyncComponent from "../../../Helper/AsyncComponent";
import styles from "./Input.css";

const InputText = asyncComponent(() => {
  return import("./InputText/InputText");
});

const NumberInput = asyncComponent(() => {
  return import("./InputNumber/InputNumber");
});

const DecimalInput = asyncComponent(() => {
  return import("./InputDecimal/InputDecimal");
});
const PasswordText = asyncComponent(() => {
  return import("./InputPassword/InputPassword");
});
const Toggle = asyncComponent(() => {
  return import("./Toggle/Toggle");
});
const AddressInput = asyncComponent(() => {
  return import("./InputAddress/InputAddress");
});
const SelectInput = asyncComponent(() => {
  return import("./InputSelect/InputSelect");
});
const ImageInput = asyncComponent(() => import("./InputImage/InputImage"));

const MultilineInput = asyncComponent(() =>
  import("./InputMultiline/InputMultiline")
);

const DateInput = asyncComponent(() => import("./InputDate/InputDate"));

const SearchInput = asyncComponent(() => import("./InputSearch/InputSearch"));

const wrapInContainer = (input, props) => {
  //Getting element Configuration
  const elementConfig = props.elementConfig ? props.elementConfig : {};
  //Setting FontSize
  const fontSize = !isNaN(parseInt(elementConfig.fontSize))
    ? { fontSize: parseInt(elementConfig.fontSize) + "px" }
    : { fontSize: "var(--font-size)" };
  //Getting Label
  const textLabel = props.label ? (
    <label style={{ ...fontSize }} className={styles.InputLabel}>
      {props.label}
    </label>
  ) : null;

  const inputBox = (
    <div className={styles.InputBox}>
      {textLabel}
      <div className={styles.InputForm}>{input}</div>
      <span className={styles.InputMessage}></span>
    </div>
  );

  return inputBox;
};

const Input = (props) => {
  //Getting Input Type
  const inputType = props.type ? props.type.toLowerCase() : "text";

  // console.log(props);
  let input;
  switch (inputType) {
    case "password":
      input = wrapInContainer(<PasswordText {...props} />, props);
      break;
    case "toggle":
      input = <Toggle {...props} />;
      break;
    case "address":
      input = wrapInContainer(<AddressInput {...props} />, props);
      break;
    case "select":
      input = wrapInContainer(<SelectInput {...props} />, props);
      break;
    case "image":
      input = <ImageInput {...props} />;
      break;
    case "multiline":
      input = wrapInContainer(<MultilineInput {...props} />, props);
      break;
    case "date":
      input = wrapInContainer(<DateInput {...props} />, props);
      break;
    case "search":
      input = wrapInContainer(<SearchInput {...props} />, props);
      break;
    case "number":
      input = wrapInContainer(<NumberInput {...props} />, props);
      break;
    case "decimal":
      input = wrapInContainer(<DecimalInput {...props} />, props);
      break;
    default:
      input = wrapInContainer(<InputText {...props} />, props);
  }

  return input;
};

export default Input;
