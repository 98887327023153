import styles from "./Logo.css";
import sudhyaLogo from "../../assets/images/sudhya_200x200.png";

const Logo = (props) => (
  <div className={styles.Logo}>
    <img src={sudhyaLogo} alt="Sudhya" />
  </div>
);

export default Logo;
